<template>
  <div>
    <portal to="page-top-title">Category #{{ id }}</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'contactLists-categories-edit', params: { id: id } }" icon="fa-edit" text>Edit</btn>
    </portal>

    <box-single :url="`/contact-lists/categories/${id}`" data-prop="category" resp-prop="data" loader>
      <template slot="content">
        <row>
          <column-info title="Name">{{ category.name }}</column-info>
          <column-info title="Group" v-if="category.group">
            <router-link :to="{ name: 'categoryGroups-single', params: { id: category.group.id } }">{{ category.group.name }}</router-link>
          </column-info>
          <column-info title="Created At">{{ category.created_at }}</column-info>
          <column-info title="Updated At">{{ category.updated_at }}</column-info>
        </row>
        <row>
          <column-info title="Lists">
            <badge title="Total">{{ category.lists_count_total }}</badge>
            <badge title="Active" color="green">{{ category.lists_count_active }}</badge>
            <badge title="Inactive" color="red">{{ category.lists_count_inactive }}</badge>
          </column-info>
        </row>
      </template>
    </box-single>

    <box-table title="Lists" url="/contact-lists" :url-query="{ category_id: id }" data-prop="lists" loader>
      <template slot="box-tools-right">
        <btn :route="{ name: 'contactLists-new', query: { category: id } }" size="xs" icon="fa-plus" text>Add New</btn>
      </template>
      <template slot="table-header">
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Price</th>
          <th>Custom ID</th>
          <th>Contacts</th>
          <th class="text-center">Live</th>
          <th class="text-center">Active</th>
          <th class="text-center">Status</th>
          <th class="text-right">Created At</th>
          <th class="text-right"></th>
        </tr>
      </template>
      <template slot="table-body">
        <table-row v-for="list in lists" :key="`lists-tr-${list.id}`" :value="list">
          <td>{{ list.id }}</td>
          <td>{{ list.name }}</td>
          <td>{{ priceFormatter.format(list.price) }}</td>
          <td>
            <code>{{ list.custom_id }}</code>
          </td>
          <td>
            <badge title="Total">{{ list.contacts_count_total }}</badge>
            <badge title="Active" color="green">{{ list.contacts_count_active }}</badge>
            <badge title="Inactive" color="red">{{ list.contacts_count_inactive }}</badge>
          </td>
          <td class="text-center">
            <badge-active :value="list.live_list"/>
          </td>
          <td class="text-center">
            <badge-active :value="list.active"/>
          </td>
          <td class="text-center">
            <badge-status :value="list.status_text"/>
          </td>
          <td class="text-right">{{ list.created_at }}</td>
          <td class="text-right">
            <btn :route="{ name: 'contactLists-edit', params: { id: list.id } }" icon="fa-edit" v-if="list.status !== 5">Edit</btn>
            <btn :route="{ name: 'contactLists-single', params: { id: list.id } }" color="info" icon="fa-eye">Show</btn>
          </td>
        </table-row>
      </template>
    </box-table>
  </div>
</template>

<script>
import BoxSingle from '@/views/components/data/BoxSingle'
import BoxTable from '@/views/components/data/BoxTable'
import Btn from '@/views/components/simple/Btn'
import Badge from '@/views/components/simple/Badge'
import BadgeActive from '@/views/components/simple/BadgeActive'
import BadgeStatus from '@/views/components/simple/BadgeStatus'

export default {
  name: 'ContactListsCategoriesSingle',
  metaInfo () {
    return { title: 'ContactLists | Category #' + this.id }
  },
  components: {
    BoxSingle,
    BoxTable,
    Btn,
    Badge,
    BadgeActive,
    BadgeStatus
  },
  computed: {
    id () { return this.$route.params.id }
  },
  data () {
    return {
      category: {},
      lists: [],
      priceFormatter: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })
    }
  }
}
</script>
